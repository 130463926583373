import ClientModule from "@gqlapp/module-client-react";
import Home from "@xem/home-client-react";
import User from "@xem/user-client-react";
import Qr from "@xem/qr-client-react";
import Link from "@xem/link-client-react";
import Domain from "@xem/domain-client-react";
import Stats from "@xem/stats-client-react";
import Taxonomy from "@xem/taxonomy-client-react";
import Webrisk from "@xem/webrisk-client-react";
import Gen from "@xem/gen-client-react";
import Test from "@xem/test-client-react";
import Store from "@xem/store-client-react";
import Page from "@xem/page-client-react";
import CustomPage from "@xem/custom-page-client-react";
const Base = new ClientModule(
	Qr, Stats, Home, Page,
	User,
	Link, Domain, Taxonomy, Webrisk, Gen, Store,
	CustomPage,
	Test
);
export default Base;
