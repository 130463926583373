import ClientModule from "@gqlapp/module-client-react";
import withCurrentUserQuery from '@xem/user-common/classes/withCurrentUserQuery'
import { withContentCallback } from '@xem/entity/classes/preloadContents';
import withLogout from "./withLogout";
import USER_QUERY from "@xem/user-common/graphql/UserQuery.graphql";

export default new ClientModule({
	withPreProps: [
		withCurrentUserQuery,
		withLogout,
		withContentCallback(((res)=> res), 'loadUser', USER_QUERY)
	]
})