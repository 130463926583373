import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withContentCallback } from '@xem/entity/classes/preloadContents';
import Domains from "@xem/domain-common/classes/Domains";
import DOMAINS_QUERY from '@xem/domain-common/graphql/domainsQuery.graphql';
import QueryLoading from '@xem/entity/classes/QueryLoading';



// function domainSearch(props: any){
// 	let { setFieldValue, loadDomain } = props;
// 	let domains = Domains.instanse({
// 		filter: {
// 			type: ['ns'],
// 			get_default: true,
// 			public: true
// 		}
// 	});
//
// 	return async function (){
// 		setFieldValue('pageLoading', true)
// 		loadDomain && domains.update(await loadDomain(domains.variables));
// 		setFieldValue('pageLoading', false)
// 		return domains;
// 	}
// }

function domainSearch(props: any){
	let { setFieldValue, loadDomain } = props;
	let domains = Domains.instanse({ filter: { type: ['ns'], get_default: true, public: true}});

	return async function (){
		setFieldValue('pageLoading', true)
		loadDomain && domains.update(await loadDomain(domains.variables));
		setFieldValue('pageLoading', false)
		return domains;
	}
}

export function domainCallback(props: any){
	let { onActions } = props || {};
	let search = domainSearch(props);
	return async function (rest: any){
		let options = [], domains = await search();
		domains.items.map((domain)=>{
			options.push({
				value: domain.id,
				label: domain.full
			})
		})

		onActions['domain.search'] && onActions['domain.search']({ ...rest, options })
	}
}


export default compose(
	withContentCallback(((res: any)=> res), 'loadDomain', DOMAINS_QUERY),
	withProps((props)=>({ domainSearch: domainSearch(props) })),
	withProps(({ domainSearch, onActions  })=>{
		function domainCallback(values: any){
			let search = domainSearch(values);
			return async function (rest: any){
				let options = [], domains = await search();
				domains.items.map((domain: any)=>{
					options.push({
						value: domain.id,
						label: domain.full
					})
				})

				onActions['domain.search'] && onActions['domain.search']({ ...rest, options })
			}
		}

		return { domainCallback }
	})
)