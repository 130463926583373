import { withFormik } from "formik";
import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";

export default  compose(
  withFormik({
    mapPropsToValues: ({ entity }: any)=> ({
      onSelect: 0,
      name: entity?.name,
      note: entity?.note,
      root: entity?.root || {},
      nodes: entity?.nodes || [],
      size: [0, 0, 0, 0]
    }),
    handleSubmit: ({ nodes, name, note }, { props, props: { onSave  }}: any & { props: any }) => {
      onSave( { nodes, title: name, body: note })
        .then(()=>msg({ message:'done'}))
        .catch(errorCatch)
      ;
    },
    enableReinitialize: true,
    displayName: 'FilterForm',
  }),
  withOnFilter
)