import React from 'react';
import menu from "./helpers/menu";
import resources from './locales';
import Route from "@xem/router/classes/Route";
import List from './containers/ListContainer';
import Intro from './containers/IntroContainer';
import CustomPage from './containers/CustomPageContainer';
import ClientModule from "@gqlapp/module-client-react";
import { CustomEditRoute as ER, CustomPageRoute as PR, map } from "./helpers/config";

/**
 *
 * @param props
 * @param component
 * @param options
 * @param path
 */
function getProps(props: any, component: any, options: any, path: string = null){
  let getInitialProps = component?.getInitialProps;
  let newProps = {...props, ...options, component, getInitialProps}
  if(path){
    Object.assign(newProps, { path: map({ path }) })
  }
  return newProps
}

export default new ClientModule(menu, {
  route: {
    CustomPageIntro : <Route {...getProps(ER, Intro, { load: false, blockMenus: ['createlink'] }, 'intro')} />,
    CustomPageList  : <Route {...getProps(ER, List, { load: false, blockMenus: ['createlink'] }, 'list')} />,
    CustomPage      : <Route {...getProps(PR, CustomPage, { unloginedClass: ''})} />
  },
  localization: [{ ns: 'page', resources, more: ['common'] }]
});
