import { Maybe, Nothing } from "purify-ts";

export type ID = string;

export type MapNode = {
  id: ID;
  type?: NodeType;
  nodes: ReadonlyArray<MapNode>;
};

type NodeType = "children" | 'spouse'

export type MapNodeInfo = {
  id: ID;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  color: {
    stroke: string;
    fill: string;
  };
};

type NodeArray = MapNodeInfo[]


type NodeObject = {
  [id: string]: MapNodeInfo;
}

export type State = {
  root: MapNode;
  x?: number,
  y?: number,
  nodes: NodeObject | NodeArray
};

const defaultColor = {
  stroke: "var(--blue-200)",
  fill: "var(--blue-50)",
};

// @ts-ignore
export const initialState: State = {
  nodes: [
    {
      id: "0",
      position: {
        x: 180, y: 50
      },
      size: {
        width: 100,
        height: 100,
      },
      color: {
        stroke: "var(--pink-400)",
        fill: "var(--pink-100)",
      },
    },
    {
      id: "1",
      position: { "x": 50, "y": 250 },
      size: { width: 100, height: 150 },
      color: defaultColor,
    },
    {
      id: "2",
      position: { "x": 250, "y": 250 },
      size: { width: 100, height: 120 },
      color: defaultColor,
    },
    {
      id: "3",
      position: { "x": 450, "y": 250 },
      size: { width: 100, height: 100 },
      color: defaultColor
    }
  ],
  root: {
    id: "0",
    nodes: [
      {
        id: "1",
        type: "spouse",
        nodes: []
      },
      {
        id: "2",

        nodes: [],
      },
      {
        id: "3",
        nodes: [],
      },
    ],
  }
};

export const update = (
  doc: State,
  maybeId: Maybe<string>,
  changes: Partial<MapNodeInfo>
) => {
  return maybeId.caseOf({
    Nothing: () => doc,
    // @ts-ignore
    Just: (id: number) => {
      const node = doc.nodes[id];
      if (node) {
        return {
          ...doc,
          nodes: {
            ...doc.nodes,
            [id]: { ...node, ...changes },
          },
        };
      }
      return doc;
    },
  });
};
