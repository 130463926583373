import { graphql } from 'react-apollo';
import GEN_UPDATE from "../graphql/GenUpdate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 * withLinkUpdate
 * @param Component
 */
export const withGenUpdate = graphql(GEN_UPDATE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    genUpdate: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        )
      )
  })
});


export default withGenUpdate;
