import ClientModule from "@gqlapp/module-client-react";

import compose from "recompose/compose";
import name from "./name";
import password from "./password";
import mail from "./mail";
import phone from './phone';
import birthday from './birthday';
import address from './address';
import role from './role';
import avatar from './avatar';
import info from './info';
import code from './confirmCode';

const popupComponents = [
	{ component: compose(info, name, password, mail, phone, birthday, address, role, avatar, code) }
]

export default new ClientModule({ popupComponents });