import React from "react";
import dynamic from "next/dynamic";
import compose from "recompose/compose";
import renderCallback from "@xem/entity/classes/renderCallbackR";

const Form = dynamic(() => import('./Form').then((c) => c.default));

const DataForm = ((props) => (
	<>{!__SERVER__ && (!!props?.isOpen) && <><Form {...props} /></>}</>));

let refCreate = React.createRef();
let refUpdate = React.createRef();
export default compose(
	renderCallback(refCreate, 'gen.people.add', DataForm, { title: `gen:form.people.add.title`.t() }),
	renderCallback(refUpdate, 'gen.people.update', DataForm, { title: `gen:form.people.update.title`.t() }),
	renderCallback(refCreate, 'gen.people.children', DataForm, { title: `gen:form.people.children.title`.t() }),
	renderCallback(refCreate, 'gen.people.spouses', DataForm, { title: `gen:form.people.spouses.title`.t() }),
)

