import settings from '@xem/config';
import Action from '@xem/entity/classes/Action';

const custompage = {
  list: '/pages.html',
  listpage: '/pages/:page/page.html',
  create: '/page/create.html',
  edit: '/page/:id/edit.html',
  intro: '/page.html'
}

export const map = ({ id, page, path }: any) => `${custompage[path]}`.replace(/:id/, id).replace(/:page/, page);

const perms = ['isMember'];
const styleConfigs = { style: { '--button-bar-height': '50px' } };
const styleEditConfigs = { style: { '--button-bar-height': '0px', '--page-header-height': '0px', '--page-footer-height': '0px' } };


export const defaultRoute = { ...settings.route, exact: true, action: Action.instanse() }
export const CustomPageRoute = { ...defaultRoute, ...styleEditConfigs, classNameOnPage: 'theme-page', webType: 'page', runtimejs: false }
export const CustomEditRoute = { ...defaultRoute, ...styleConfigs, perms, runtimejs: true, webType: 'cpanel', mobile: { show: true }, classNameOnPage: 'cpanel theme-mobile'}

export default custompage;
