 import { Maybe, Nothing } from "purify-ts";

export type ID = string;

export type MapNode = {
  id: ID;
  nodes: ReadonlyArray<MapNode>;
};

export type MapNodeInfo = {
  id: ID;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
  color: {
    stroke: string;
    fill: string;
  };
};

export type State = {
 root?: MapNode;
 x?: number,
 y?: number,
 nodes: MapNodeInfo[]
};

export const update = (
  doc: State,
  maybeId: Maybe<string>,
  changes: Partial<MapNodeInfo>
) => {
  return maybeId.caseOf({
    Nothing: () => doc,
    // @ts-ignore
    Just: (id: number) => {
      const node = doc.nodes[id];
      if (node) {
        let nodes = doc.nodes;
        nodes.map((values, index)=>{
          if(values.id === `${id}`){
            nodes[index] =  { ...node, ...changes }
          }
        });
        return { ...doc, nodes }
      }
      return doc;
    },
  });
};
