export default class Gen {
  public id: number;
  public name: string;
  public note: string;
  public root: any
  public nodes: any[]
  /**
   * instanse
   */
  public static instanse() {
    return new this();
  }
  
  /**
   * save
   */
  public save({ id, name, note, root, nodes }: any) {
    this.id     = id;
    this.name   = name;
    this.note   = note;
    this.root   = root;
    this.nodes  = nodes;
  }
}
