import withProps from 'recompose/withProps';
import withRouteLink from './withRouteLink';
import withTaxonomies from '@xem/taxonomy-common/classes/withTaxonomies';
import Taxonomies from '@xem/taxonomy-common/classes/Taxonomies';
import Charts from '@xem/stats-common/classes/Stats/Charts';
import Stats from '@xem/stats-common/classes/Stats/Stats';
import withWebShare from '@xem/entity-client-react/components/withWebShare';
import withStatsLocation from "@xem/stats-common/classes/withStatsLocation";
import withStatsDevice from "@xem/stats-common/classes/withStatsDevice";
import withStatsChannel from "@xem/stats-common/classes/withStatsChannel";
import withStatsChart from "@xem/stats-common/classes/withStatsChart";
import loadable from 'next/dynamic';

import withRenderProcess from "@xem/entity/classes/withRenderProcess";
import withFormFilter from '../helpers/link/Menu';
import withMenu from "../helpers/link/withMenu";
import withCompose from "../helpers/link/Menu.Utils";
import qrcodeLoadfunc from "../helpers/qrcode.loadfunc";
const Detail = loadable(
  ()=>import("../components/views/DetailView").then(
    (c) => c.default)
);

let timeout = null;

const DetailContainer = withRouteLink(
  ({ query: { id } = {} }) => ({
    stats: Stats.instanse(),
    charts: Charts.instanse(),
    taxonomies: Taxonomies.instanse(),
    statsProps: { id: parseInt(id), skip: !parseInt(id) }
  }),
  [
    withCompose,
    withMenu,
    qrcodeLoadfunc,
    withRenderProcess(),
    withFormFilter,
    withTaxonomies({}),
    withWebShare,
    withStatsLocation(({ statsProps })=>({ ...statsProps, skip: !!__SERVER__ })),
    withStatsDevice(({ statsProps, DeviceType: type })=>({ ...statsProps, type })),
    withStatsChannel(({ statsProps })=>({ ...statsProps })),
    withStatsChart(({ statsProps, charts })=>({ ...charts.variables,...statsProps })),
    withProps(({ entity, navigation, stats, actions, start_time }) => {
      let {  ips } = stats || {}, {  title  } = entity || {};
      /** Open QR */
      function toggleQR(e){
        (actions['qrcode']) && actions['qrcode'](e)
      }

      if(isTrue('DEBUG')){
        let end_time = (new Date()).getTime(), run_time = end_time - start_time;
        console.warn('####### PAGE', { start_time, end_time, run_time})
      }

      return { title, ips, toggleQR };
    }),
  ]
)(Detail)

export default DetailContainer;
