import withProps from "recompose/withProps";

export default withProps({
  sx: {
    height: '100%',
    minHeight: '100%',
    position: 'relative',
    svg:{ maxHeight: 'inherit' },
    '&':{
      '--gray-300': 'rgb(209, 213, 219)',
      '--gray-400': 'rgb(156, 163, 175)',
      '--blue-50': 'rgb(239, 246, 255)',
      '--blue-100': 'rgb(219, 234, 254)',
      '--blue-200': 'rgb(191, 219, 254)',
      '--blue-300': 'rgb(147, 197, 253)',
      '--blue-400': 'rgb(96, 165, 250)',
      '--pink-50': 'rgb(253, 242, 248)',
      '--pink-100': 'rgb(252, 231, 243)',
      '--pink-200': 'rgb(251, 207, 232)',
      '--pink-300': 'rgb(249, 168, 212)',
      '--pink-400': 'rgb(244, 114, 182)'
    }
  }
})