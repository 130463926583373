import withProps from "recompose/withProps";
import compose from "recompose/compose";

// @ts-ignore
import Style from './Filter.Styles';

export default compose(
  Style,
  withProps(({ onActions, setFieldValue })=>{
    const peopleCallback = function peopleCallback(values: any, { onClose }){
      onClose && onClose();
      debug({ values })
    }

    const callback = ({ name, note }: any, { onClose }) => {
      onClose && onClose();
      setFieldValue('name', name)
      setFieldValue('note', note)
      onActions['gen.people.add'] && onActions['gen.people.add']({ callback: peopleCallback })
    }

    const onGenCreate = function onGenCreate(e){
      e.preventDefault();
      onActions['gen.create'] && onActions['gen.create']({ callback })
    }

    return { onGenCreate }
  })
);
