import ClientModule from '@gqlapp/module-client-react';
import { ApolloLink } from 'apollo-link';
// @ts-ignore
import { apiUrl } from "@xem/config/settings";
import { BatchHttpLink } from 'apollo-link-batch-http';
import { extractFiles } from "extract-files";
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';

// debug({ apiUrl })
export default new ClientModule({
	createNetLink : apiLink => ApolloLink.split(
		({ operationName: op }) => {
			// /**
			return !(['qrtext', 'textToPath', 'dataToSvg', 'dataToBase64']
				.map(v => v.toLowerCase()))
				.includes(`${op}`.toLowerCase())
			/*/
			return ['login', 'logout'].includes(`${op}`.toLowerCase())
			/**/
		},
		new BatchHttpLink({ uri: apiUrl, credentials: 'include'}),
		ApolloLink.split(({ variables }) => (extractFiles(variables)?.files?.size > 0),
			// @ts-ignore
			createUploadLink({ uri: apiUrl, credentials: 'include'}),
			apiLink
		)
	)
});
