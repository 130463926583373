import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withLoading from '@xem/utils/withLoading';
import Entities from '@xem/custom-page-common/classes/CustomPages';
import withCustomPages, { CUSTOM_PAGES_QUERY } from '@xem/custom-page-common/classes/withCustomPages';
import withCustomPagesDeleting from '@xem/custom-page-common/classes/withCustomPagesDeleting';
import onDeletes from "../utils/onDeletes";

import withTools from "@xem/look/Renders/Tools/withTools";
import setCache from '@xem/entity/classes/setCache';

/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */
export const ModulesCompose = (input?: any, withPropsInput?: any[], withPropsBefore?: any[]) => {
	return (Route: any) => {

		const getEntities = function getPath({ query, status = 'active'}: any) {
			let id = parseInt(query?.id);

			let entities = Entities.instanse({ pageSize: 12, orderBy: 'CHANGED', protected: true });

			/**
			 * Custom page filter
			 */
			let filter: any = {};


			(!!id) && (Object.assign(filter, { tids: [id] }));
			(!!status) && (Object.assign(filter, { status }));

			return entities;
		}


		/**
		 * WithRouteBase
		 */
		const WithRouteBase = compose(
			withTools,
			withProps(input),
			withCustomPagesDeleting,
			withProps(({ customPagesDelete, query, cookie, ...props}) => ({
				onDeletes: onDeletes(customPagesDelete, props),
				entities: getEntities({ query, cookie: cookie?.filter?.key})
			})),
			withProps(({ entities, action })=>{
				function refetch(){
					func(entities?.refetch)()
				}

				action.callback = refetch

				return ({ refetch })
			}),
			...(withPropsBefore || []),
			withCustomPages(({ entities }) => ({ customPages: entities })),
			...(withPropsInput || []),
			withLoading
		)(Route);


		const bindCache = async (apolloClient: any, query: any, obj: any)=> {
			let entities = getEntities(obj)
			let variables = entities.variables;

			/** set cache*/
			await setCache({ query, variables, apolloClient })
		}


		// @ts-ignore
		WithRouteBase.getInitialProps = async ({ apolloClient}) => {
			let ctx = {};
			await bindCache(apolloClient, CUSTOM_PAGES_QUERY, {})
			return ({...ctx})
		}

		return WithRouteBase;
	};
};

export default ModulesCompose;
