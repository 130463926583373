import React, {ReactNode, useRef, useState} from "react";
import { type MapNode, State, update } from "./tree/store";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { Just, Maybe, Nothing } from "purify-ts";
import Styles from './Styles';

import withMenus from "./withMenus";

/**
 *
 * @param n
 *
 * nodeProps
 */
export function renderProps(n: any): any {
  let { color, position, size } = n || {};
  return {
    stroke: color?.stroke,
    fill: color?.fill,
    x: position?.x,
    y: position?.y,
    w: size?.width,
    h: size?.height
  }
}

export const getMousePos = function (svg: SVGSVGElement, e: { clientX: number; clientY: number }) {
  if (svg) {
    const ctm = svg.getScreenCTM();
    if (ctm) {
      const x = (e.clientX - ctm.e) / ctm.a;
      const y = (e.clientY - ctm.f) / ctm.d;
      return { x, y };
    }
  }
  return { x: 0, y: 0 };
}


export function changeOffset(view: number, value: number, old: number, size: number){
  value -= old;
  value *= size;
  return view - value;
}


export function map(state: any, selectedNode){
  return function (nodes: ReadonlyArray<any>, parent: any ){
    if (nodes.length === 0) {
      return null;
    }

    return nodes
      .map((node: any) => {
        const n = state.nodes[node.id];
        const p = state.nodes[parent.id];
        let children = map(state, selectedNode)(node.nodes, node);

        return ({
          ...children, parent, n, p,
          ...renderProps(n),
          isSelected: selectedNode.equals(Just(n.id))
        });
      }).flat()
  }
}

export function onMouseOver(setSelectedNode: any) {
  return function (e: any){
    const target = e.nativeEvent.target as SVGElement;
    setSelectedNode(target.classList.contains("node") ? Just(target.id) : Nothing)
  }
}


export function onClick(setSelectedNode: any, callback?: any) {
  return function (e: any){
    const target = e.nativeEvent.target as SVGElement;
    setSelectedNode(target.classList.contains("node") ? Just(target.id) : Nothing)
    if((target.classList.contains("node")) &&  (e.detail == 2)){
      func(callback)(e);
    }
  }
}

export function onStart({ setOffset, viewX, viewY, setElId, setDragOffset }){
  return function onStart({ node, event, top, left }){
    const target = event.target as SVGElement;
    setOffset({ viewX, viewY, top, left });

    if (!target.classList.contains("node")) return;
    setElId(Just(target.id));

    const { x, y } = getMousePos(node, event);

    setDragOffset({
      x: x - parseFloat(target.getAttributeNS(null, "x") as string),
      y: y - parseFloat(target.getAttributeNS(null, "y") as string),
    });
  }
}


/**
 *
 * @param state
 * @param elId
 * @param selectedNode
 * @param dragOffset
 * @param setDoc
 * @param update
 * @param offset
 * @param setSize
 * @param width
 * @param height
 */
export function onMove({ state, elId, selectedNode, dragOffset, setDoc, update, offset, setSize, width, height }){
  return function ({ node, event, top, left }){
    if (node && elId.isJust() && selectedNode?.equals(elId)) {
      const { x, y } = getMousePos(node, event);
      let posX = x - dragOffset.x, posY = y - dragOffset.y;
      setDoc(update(state, elId, { position: { x: posX, y: posY } }));
    }else{
      const target = event.target as SVGElement;

      if(target.nodeName === 'svg'){
        let x = changeOffset(offset.viewX, left, offset.left, width);
        let y = changeOffset(offset.viewY, top, offset.top, height);

        setSize([x, y, width, height])
      }
    }
  }
}

/**
 *
 * @param setElId
 * @param setOffset
 * @param setDragOffset
 */
export function onStop({ setElId, setOffset, setDragOffset }){
  return function (){
    setElId(Nothing)
    setOffset({ viewX: 0, viewY: 0, top: 0, left: 0 });
    setDragOffset({ x: 0, y: 0 })
  }
}


/**
 * Save values
 * @param node
 * @param values
 */
export const nodeSave = function(node: any, values: any) {
  let id = node?.id;
  let nodes = [...values?.nodes || []];
  let maxID = Math.max(...nodes.map(o => o.id))

  let index = null;
  let exists = !!nodes.filter(({ id: i }, idx)=> {
    if(id === i){ index = idx; return true; }
    return false;
  }).length;

  if(exists && index !== null){
    Object.assign(nodes[index], node)
  }else{
    /*** set uid ***/
    if(!id){
      Object.assign(node, { id: maxID + 1 });
    }
    /*** set node ***/
    nodes.push(node);
  }

  Object.assign(values, { nodes })
  return values;
}

export function buildFamilyTree(nodes: any[]) {
  // Create a map of nodes for easy lookup
  const nodeMap = new Map(nodes.map(node => [node.id, { ...node }]));

  // Find the root node (the one with no parents)
  const rootNode = nodes.find(node => node.parents.length === 0);

  // Recursive function to build the tree
  function constructTree(nodeId: number, parentId = null) {

    const currentNode = nodeMap.get(nodeId);
    if (!currentNode) return null;

    let type = determineNodeType(nodeId, parentId);
    const treeNode = { id: nodeId.toString(), type,  nodes: [] }

    // Find children and spouses
    const children = nodes.filter(n => (n.parents.includes(nodeId) && ((n.parents.length === 1) || (n.parents.length > 1) && type == 'spouse')));

    const spouses = nodes.filter(n =>
      n.spouses.includes(nodeId)
    );

    // console.warn([...spouses, ...children]);

    // Recursively add children and spouses
    [...spouses, ...children].forEach(childOrSpouse => {
      const childTree = constructTree(childOrSpouse.id, nodeId);

      if (childTree) {
        treeNode.nodes.push(childTree);
      }
    });

    return treeNode;
  }

  // Determine node type based on relationships
  function determineNodeType(nodeId, parentId) {
    const node = nodeMap.get(nodeId);
    if (!parentId) return 'root';
    return (node.spouses.includes(parentId)) ? 'spouse': 'children';
  }

  // Start building the tree from the root node
  return constructTree(rootNode.id);
}


export const renderRects = ({ selectedNode, nodeMap, nodes, parent, render }: any): Array<any> | null => {

  return (nodes || [])
    .map((node: any) => {
      const parentId = parent?.id;
      const nodeId = node?.id;
      const n = nodeMap[nodeId];
      const p = nodeMap[parentId];

      const children = renderRects({
        selectedNode, nodeMap, parent: node, nodes: node?.nodes || [], render
      });

      return [
        ...(children ? children : []),
        render({
          n, p, parentId, nodeId,
          props: renderProps(n), selectedNode,
          isSelected: selectedNode?.equals(Just(nodeId)) })
      ];
    })
    .flat();
}



let timeout = null;
export default compose(
  Styles,
  withMenus,
  withProps(({ values, setFieldValue, openMenu })=>{
    let { nodes } = values || {};
    const wrapper = useRef<HTMLDivElement| null>(null);
    const [size, reSize] = useState<[number, number, number, number]>(values.size);
    const [viewX, viewY, width, height] = size;

    const [elId, setElId] = useState<Maybe<string>>(Nothing);
    const [selectedNode, setSelectedNode] = useState<Maybe<string>>(Nothing);
    const [dragOffset, setDragOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [offset, setOffset] = useState<{ top: number; left: number, viewX: number; viewY: number }>({ top: 0, left: 0, viewX: 0, viewY: 0 });
    const [state, setState] = useState<State>({ nodes });

    const setSize = function (values: any){
      clearTimeout(timeout);
      timeout = setTimeout(function (){ func(setFieldValue)('size', values); }, 500)
      reSize(values)
    }

    const setDoc = function setDoc(state: State){
      clearTimeout(timeout);
      timeout = setTimeout(function (){ func(setFieldValue)('nodes', state['nodes']); }, 500)
      setState(state);
    }

    let root = buildFamilyTree(nodes)

    return {
      wrapper, size, values, root, state, setDoc, setSize, selectedNode,
      svgProps:{
        onStart: onStart({ setOffset, viewX, viewY, setElId, setDragOffset }),
        onMove: onMove({ selectedNode, state, elId, dragOffset, setDoc, update, offset, setSize, width, height }),
        onStop: onStop({ setElId, setOffset, setDragOffset }),
        onClick: onClick(setSelectedNode, function (){
          func(openMenu)(selectedNode)
        }),
        onMouseOver: onMouseOver(setSelectedNode)
      }
    }
  })
)