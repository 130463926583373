import withProps from "recompose/withProps";
import options from "./options";
import Menus from "@gqlapp/core-common/Menus";
import processMenu from '@xem/entity-client-react/helpers/utils/processMenu';

export default withProps((props: any)=>{
  let actions = {}, { entity, onActions, onSave } = props
  let refetch = func(entity?.refetch)
  const menus = new Menus(props);

  onActions['link.qrcode'] = onActions['qrcode'];

  function callback(props: any){
    func(refetch)()
    func(props?.onClose)()
  }

  function actionCallback(action: string, config: any){
    return async function (e: any){
      func(e.preventDefault)();

      if(action === 'qrcode' && typeof config === 'function' && onActions['get.data.qrcode']){
        let path = `/node/${entity?.id}`;
        let link = await onActions['get.data.qrcode']({ path });
        config = config(link?.qr);
      }

      if(!action && typeof config === 'function'){ config(e); }
      else{
        onActions && onActions[action] && onActions[action]({ ...config, entityType: 'link' });
      }
    }
  }

  options
    .map(({ onClick: func, action, accessType, id: vID,...value}: any)=>{
      let { id } = entity;
      let access = ['back', 'menu', 'qrcode'].includes(vID)  || !!(entity?.access && entity?.access[accessType]);

      let onClick = func && actionCallback(action, func({ id, access, entity, refetch, onSave, callback }))

      Object.assign(value, { id: vID, access, onClick })

      if(onClick && vID && vID === 'qrcode'){
        actions[vID] = onClick;
      }

      return value;
    })

    .filter(({ access })=>(!!access))
    .map(function ({ id, icon, ...values}){

      if(icon){
        let Icon = icon;
        Object.assign(values, { content: <Icon height={24} /> })
      }

      Object.assign(values, { id, name: `link:menu.${id}`.t() })
      return values
    }).map((value: { id: string } & any)=>{
    processMenu(menus, value, props );
  })



  let navigation = [];
  const submenu = [];

  /** add to navigation */
  menus.tree.map(({ isActive, children, ...rest }) => {
    if (isActive && children.length) {
      (children || []).map((menu: any) => submenu.push(menu));
    }
    navigation.push(Object.assign(rest, { isActive, children }));
  });


  return { navigation, actions  }
})