import { graphql } from 'react-apollo';
import GEN_CREATE from "../graphql/GenCreate.graphql";
import loading from "@gqlapp/base/utils/loading";
import map from "@gqlapp/base/utils/map";

/**
 * withLinkCreate
 * @param Component
 */
export const withGenCreate = graphql(GEN_CREATE, {
  props: ({ mutate, ownProps: { loadbar } }: any) => ({
    genCreate: async (variables: any) =>
      loading(loadbar, async () =>
        map(
          map(await mutate({ variables }), ({ data }: any) => data),
          ({ res }: any) => res
        )
      )
  })
});
export default withGenCreate
