import IconBack from '../icons/control/Back.svg'
import IconData from '../icons/info.data.svg';
import IconEdit from '../icons/info.edit.svg';
import IconRedirect from '../icons/info.redirect.svg';
import IconQrcode from '../icons/info.qrcode.svg';
import IconAdvanced from '../icons/info.advanced.svg';
import IconCampaign from '../icons/info.campaign.svg';
import IconProtected from '../icons/info.protected.svg';
import IconMenu from '@xem/icon/footer/Menu.svg'
import onQrSubmit from "@xem/qr-client-react/utils/onSubmit";

export default [
  { id: 'back',
    icon: IconBack,
    onClick: function (){
      return function ({}){
        historyBack()
      }
    }
  },
  {
    id: 'custom',
    action: 'link.custom',
    accessType: 'accessCustom',
    icon: IconEdit,
    onClick: function (rest: any){
      return { ...rest, title: 'link:form.custom.title'.t() }
    }
  },
  {
    id: 'display',
    action: 'link.display',
    accessType: 'accessCustom',
    icon: IconData,
    onClick: function ({ entity, ...rest }){
      return {
        ...rest, entity, title: 'link:form.display.title'.t(),
        accessDisplay: entity?.access?.accessDisplay
      }
    }
  },
  {
    id: 'advanced',
    action: 'link.advanced',
    accessType: 'accessData',
    icon: IconAdvanced,
    onClick: function (rest: any){
      return { ...rest, title: 'link:form.advanced.title'.t() }
    }
  },
  {
    id: 'qrcode',
    action: 'qrcode',
    icon: IconQrcode,
    onClick: function ({ entity, callback, onSave }){
      return function (values: any){
        return {
          values,
          isQrTag: true,
          toDefault: !!entity?.access?.accessQrDefault,
          data_text: entity?.full,
          onSubmit: onQrSubmit(onSave, entity, callback)
        }
      }
    }
  },
  {
    id: 'redirect',
    action: 'link.redirect',
    accessType: 'accessStore',
    icon: IconRedirect,
    onClick: function (rest: any){
      return { ...rest, title: 'link:form.redirect.title'.t() }
    }
  },
  {
    id: 'campaign',
    action: 'link.campaign',
    accessType: 'accessCampaign',
    icon: IconCampaign,
    onClick: function (rest: any){
      return { ...rest, title: 'link:form.campaign.title'.t() }
    }
  },

  {
    id: 'protected',
    action: 'link.protected',
    accessType: 'accessSercurity',
    icon: IconProtected,
    onClick: function (rest: any){
      return { ...rest, title: 'link:form.protected.title'.t() }
    }
  },

  {
    id: 'menu',
    action: 'Menu',
    icon: IconMenu,
    onClick: function ({}){ return {} }
  }
]