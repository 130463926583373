import withProps from "recompose/withProps";


/**
 *
 */
export const withMenus = withProps((props: any)=>{
  let { onActions, values, setFieldValue } = props

  /**
   * Run action
   * @param rest
   * @param type
   * @param title
   */
  const handleClick = ({ rest, type, title }: { rest: any, type: string, title: string }) => (e:any) =>{
    e && e.preventDefault();
    onActions[type] &&
    func(onActions[type]({...rest, values, title }))
  }

  /**
   * Map menu
   * @param id
   * @param rest
   * @param type
   * @param title
   * @param icon
   * @param headerTitle
   */
  const menuMap = ({ id, rest, type, title, icon = null, headerTitle = null }: any) =>{
    headerTitle = headerTitle || title;
    return ({
      icon,
      title,
      tooltip: `tooltip-${type}-${id}`,
      handleClick: handleClick({ rest, type, title: headerTitle })
    })
  }



  return {
    openMenu: function (e){
      let menus = [], items = [];

      let rest = {}

      items.push(menuMap({
        id: 1, type: 'gen.people.update', title: 'gen:menu.edit'.t(),
        rest: {...rest, callback: function (values: any, props: any) {
            debug('#######', values)
          }}
      }));

      items.push(menuMap({
        id: 2, type: 'gen.people.add', title: 'gen:menu.add.children'.t(),
        rest: {...rest, callback: function (values, props) {
            debug('#######', values)
          }}
      }));

      items.push(menuMap({ id: 3, type: 'gen.people.spouses', title: 'gen:menu.add.spouses'.t(),
        rest: {...rest, callback: function (values, props) {
            debug('#######', values)
          }}
      }));

      items.push(menuMap({ id: 4, type: 'settings', title: 'gen:menu.settings'.t(),
        rest: {...rest, callback: function (values, props) {
            debug('#######', values)
          }}
      }));


      items.push({
        title: 'gen:menu.repos'.t(),
        handleClick: ()=>{
          // store.setRepos(id);
          // setRandom(Math.random());
        }
      });

      items.push({
        title: 'gen:menu.delete'.t(),
        // handleClick: OpenDeleteConfirm(rest)
      })

      menus.push({ items, type: 'group', label: `Action`.t(), inside: true });

      func(onActions?.clicks)({
        items: menus,
        title: 'page:form.page.config.title'.t()
      })
    }
  }
});

export default  withMenus;