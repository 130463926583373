import React from 'react';
import Connection from "./tree/Connection";
import Node, { nodeProps } from "./tree/Node";
import type { MapNode, State } from "./tree/store";
import { initialState, update } from "./tree/store";
import { Maybe, Just, Nothing } from "purify-ts";
import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import SvgMap from "@gqlapp/base/utils/Interactive/Interactive";
import { onClick, onMouseOver, onMove, onStart, onStop } from "@xem/gen-client-react/components/Gen/Utils";

function renderProps(n: any): nodeProps {
	return {
		stroke: n.color.stroke,
		fill: n.color.fill,
		x: n.position.x,
		y: n.position.y,
		w: n.size.width,
		h: n.size.height
	}
}

let sx={
	height: '100%',
	minHeight: '100%',
	position: 'relative',
	svg:{ maxHeight: 'inherit' },
	'&':{
		'--gray-300': 'rgb(209, 213, 219)',
		'--gray-400': 'rgb(156, 163, 175)',
		'--blue-50': 'rgb(239, 246, 255)',
		'--blue-100': 'rgb(219, 234, 254)',
		'--blue-200': 'rgb(191, 219, 254)',
		'--blue-300': 'rgb(147, 197, 253)',
		'--blue-400': 'rgb(96, 165, 250)',
		'--pink-50': 'rgb(253, 242, 248)',
		'--pink-100': 'rgb(252, 231, 243)',
		'--pink-200': 'rgb(251, 207, 232)',
		'--pink-300': 'rgb(249, 168, 212)',
		'--pink-400': 'rgb(244, 114, 182)'
	}
}

export default (() => {
	const wrapper = useRef<HTMLDivElement| null>(null);

	const [size, setSize] = useState<[number, number, number, number]>([0,0,0,0]);

	debug(initialState);

	const [state, setDoc] = useState<State>(initialState);
	const [viewX, viewY, width, height] = size, viewBox = size.join(" ");

	const [selectedNode, setSelectedNode] = useState<Maybe<string>>(Nothing);
	const [elId, setElId] = useState<Maybe<string>>(Nothing);
	const [dragOffset, setDragOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
	const [offset, setOffset] = useState<{ top: number; left: number, viewX: number; viewY: number }>({ top: 0, left: 0, viewX: 0, viewY: 0 });


	function map(nodes: ReadonlyArray<MapNode>, parent: MapNode ){
		if (nodes.length === 0) {
			return null;
		}

		return nodes
			.map((node) => {
				const n = state.nodes[node.id];
				const p = state.nodes[parent.id];
				let children = map(node.nodes, node);

				return ({
					...children, parent, n, p,
					...renderProps(n),
					isSelected: selectedNode.equals(Just(n.id))
				});
			}).flat()
	}

	const renderRects = (
		nodes: ReadonlyArray<MapNode>,
		parent: MapNode
	): ReadonlyArray<JSX.Element> | null => {
		return nodes
			.map((node) => {
				const n = state.nodes[node.id];
				const p = state.nodes[parent.id];
				const children = renderRects(node.nodes, node);
				return [
					...(children ? children : []),
					<Connection key={`${parent.id}${n.id}`} start={p} end={n} />,
					<Node
						{...renderProps(n)}
						key={n.id}
						id={n.id}
						isSelected={selectedNode.equals(Just(n.id))}
					/>
				];
			})
			.flat();
	};

	const rootNode = state.nodes[state.root.id];

	React.useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			let rect = wrapper.current.getBoundingClientRect();
			if(rect.width && rect.height && width != rect.width || height !== rect.height){
				setSize([0, 0, rect.width, rect.height]);
			}
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [width, height, wrapper]);

	let nodes = map(state.root.nodes, state.root);
	if(nodes && nodes.length){
		nodes.push({
			...renderProps(rootNode),
			id: rootNode.id,
			isSelected: selectedNode.equals(Just(rootNode.id))
		})
	}


	const svgProps= {
		viewBox, className: 'canvas', component: 'svg',
		onStart: onStart({ setOffset, viewX, viewY, setElId, setDragOffset }),
		onMove: onMove({ selectedNode, state, elId, dragOffset, setDoc, update, offset, setSize, width, height }),
		onStop: onStop({ setElId, setOffset, setDragOffset }),
		onClick: onClick(setSelectedNode),
		onMouseOver: onMouseOver(setSelectedNode)
	}

	return (
		<Box {...{ sx }} ref={wrapper} suppressHydrationWarning>
			<SvgMap {...svgProps}>
				<Node
					{...renderProps(rootNode)}
					id={rootNode.id}
					isSelected={selectedNode.equals(Just(rootNode.id))}
				/>
				{renderRects(state.root.nodes, state.root)}
			</SvgMap>
		</Box>
	);
});