import { graphql } from "react-apollo";
import QUERY from "../graphql/CustomPageQuery.graphql";
import mapInput from "@gqlapp/base/utils/mapInput";
import compose from "recompose/compose";
import withProps from "recompose/withProps";


const queryCustomPage = graphql(QUERY, {
  skip: ({ skip, path }: any) => (skip || !path),
  options: ({ path, fetchPolicy, imageSkip }: any) => ({
    variables: { path, skip: imageSkip },
    fetchPolicy: fetchPolicy || 'cache-and-network',
  }),
  props({
          data: { loading, res, refetch, subscribeToMore, error},
          ownProps: { customPage }
  }: any) {
    (!!customPage) && (customPage.refetch = refetch);
    (!!customPage) && (customPage.subscribeToMore = subscribeToMore);
    (!loading && res && customPage) && (customPage.save(res))
    return { loading, errors: error ? error.graphQLErrors : null };
  }
});


export { QUERY as CUSTOM_PAGE_QUERY }

export default (input?: any) => compose(
  withProps((props: any) => mapInput(input, props)),
  queryCustomPage
);