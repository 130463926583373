const TryCatch = require('./TryCatch').default

/**
 *
 * @param props
 * @returns {TryCatch}
 */
function tryCatch(props){
	return new TryCatch(props);
}


/**
 *
 * @param func
 * @returns {(function(*): void)|*}
 */
func = (func)=>{
	return function (...args){
		try{
			func && func(...args);
		}catch (e) {}
	}
}

/**
 *
 * @param type
 * @returns {boolean}
 */
isTrue = (type)=>{
	return `${process.env[type]}`.toLowerCase() === 'true'
}