import React from "react";
import {withFormik} from "formik";
import Box from "@mui/material/Box";


const filterForm = withFormik({
  mapPropsToValues: ({ cookie }) =>
    ({ ids: [], count: 0, all: false, page: 1, onSelect: 0, defaultKey: cookie?.filter?.key }),
  enableReinitialize: true,
  displayName: 'EntitiesForm', // helps with React DevTools
});

const FormWrapper = filterForm(({ children, ...props}) =>{
  let { onActions, pageProps, entities, setFieldValue, loadbar, values: { page } } = props;
  function getMore(items, page, reset = false) {
    entities.page = page;
    if(reset){ entities.update(null, true); }
    return entities.fetchMore(entities.variables, loadbar).then(async (res)=>{
      entities.add(res);
      setFieldValue('page', page);
    }).catch(errorCatch);
  }

  /**
   * handle Page change
   * @param page
   * @returns {Promise<void>}
   */
  let handlePageChange = async (page) => {
    if (page !== entities.page) {
      let items = entities.items || [];
      await getMore(items, page);
    }
  }


  pageProps = {
    ...entities.variables,
    ...pageProps,
    page,
    pageCount: entities.pageCount,
    collectionSize: entities.count,
    handlePageChange
  }

  Object.assign(props, { parentFieldValue: setFieldValue, pageProps });

  const innerRef = ()=>{
    (onActions['Filter']) && onActions['Filter']({ ...props, isOpen: true })
  }

  return <>
    <Box ref={innerRef} sx={{ paddingTop: '0px', backgroundColor: '#f2f2f2', minHeight: 'calc(100%)' }}>
      {typeof children === 'function' && children(props)}
      {typeof children !== 'function' && children}
    </Box>
  </>
});

export default function withFormFilter(Component){
  return function filter(props){
    return (<FormWrapper {...props}>{(rest)=><Component {...rest} />}</FormWrapper>)
  }
}
