import { useState } from "react";
import type { MapNodeInfo } from "./store";

type Props = {
  start?: MapNodeInfo;
  end?: MapNodeInfo;
};

export default function Connection({ start, end }: Props) {
  const [isSelected, setIsSelected] = useState(false);

  if (!start || !end) {
    return null;
  }


  const onClick = () => {
    setIsSelected(!isSelected);
  };

  let space= 20, lines = [], connLine = "";
  let connStartX = 0, connStartY = 0, connEndX = 0, connEndY = 0;


  if (
    (start.position.x + start.size.width * 1.5 < end.position.x) ||
    (start.position.x + start.size.width < end.position.x && start.position.y < end.position.y + end.size.height * 1.5)
  ) {
    // Start is to the left of end
    connStartX = start.position.x + (start.size.width / 2);
    connStartY = start.position.y + start.size.height;
    connEndX = end.position.x + end.size.width / 2;
    connEndY = end.position.y;
  }

  else if (
    (start.position.x > end.position.x + end.size.width * 1.5) ||
    (end.position.x + end.size.width < start.position.x && start.position.y < end.position.y + end.size.height * 1.5)
  ) {
    // Start is to the right of end
    connStartX = start.position.x + start.size.width / 2;
    connStartY = start.position.y + start.size.height;

    connEndX = end.position.x + end.size.width / 2;
    connEndY = end.position.y;

  } else {
    // // Start is above or below end
    connStartX = start.position.x + start.size.width / 2;
    connEndX = end.position.x + end.size.width / 2;

    if (start.position.y < end.position.y) {
      // Start is above end
      connStartY = start.position.y + start.size.height;
      connEndY = end.position.y;
    }
    else {
      // Start is below end
      connStartY = start.position.y;
      connEndY = end.position.y + end.size.height;
    }
  }


  if( connStartX || connEndX ){
    let to = connStartY + space;
    lines.push(`${connStartX} ${to}`);
    lines.push(`${connEndX} ${to}`);
  }

  if(lines.length){
    connLine = `L ${lines.join(", ")}`
  }

  const d = `M ${connStartX} ${connStartY} ${connLine}, ${connEndX} ${connEndY}`;
  return (<path
    d={d}
    stroke="var(--blue-200)"
    strokeWidth="4"
    fill="transparent"
    onClick={onClick}
  />);
}
