import React from 'react';
import clsx from 'clsx';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import ClickToSelect from '@xem/look/ClickToSelect';
import Logo from "@xem/icon/links/logo.svg";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Button from "reactstrap/lib/Button";

import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import Pen from "@xem/icon/info/Pen";
import Table from '@gqlapp/base/Item';
import onSave from "@xem/entity/classes/onSave";
import ShareBtn, { ShareBtnMobile } from "@xem/look/ShareBtn";
import withLinkUpdate from "@xem/link-common/classes/withLinkUpdate";

import { makeStyles } from 'tss-react/mui';
import preloadContents, {withContentCallback} from "@xem/entity/classes/preloadContents";
import LINK_QUERY from "@xem/link-common/graphql/linkQuery.graphql";
import QRCODE_QUERY from "@xem/custom-page-common/graphql/CustomQrcodeQuery.graphql";
import PAGE_QUERY from "@xem/custom-page-common/graphql/CustomPageQuery.graphql";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";

const useStyles = makeStyles()((theme) => ({
	root:{
		"&> .row-1":{
			width: '350px',
			maxWidth: '350px'
		},
		"&> .row-2": {
			maxWidth: 'calc(100% - 350px)'
		},
		"@media (min-width: 800px)":{
			"&> .row-1":{
				width: '100%'
			}
		},
		"@media (max-width: 992px)":{
			"&> .row-1":{
				maxWidth: '290px'
			},
			"&> .row-2": {
				maxWidth: 'calc(100% - 290px)'
			},
		}
	}
}));

const sx = {
	"&> .row-1":{
		width: '350px',
		maxWidth: '350px'
	},
	"&> .row-2": {
		maxWidth: 'calc(100% - 350px)'
	},

	"@media (max-width: 768px)":{
		"&> .row-1":{
			width: '100%',
			maxWidth: '100%'
		}
	},
	"@media (max-width: 992px) and (min-width: 768px) ":{
		"&> .row-1":{
			maxWidth: '290px'
		},
		"&> .row-2": {
			maxWidth: 'calc(100% - 290px)'
		},
	}
}

/**
 *
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ButtonNew = ({className, ...props}) => <Button style={{ minWidth: '200px', height: '50px'}} {...props} className={clsx(className, 'rounded-md-2', 'rounded-1')} />

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DataFirst = () =>{
	return (<div className={'px-3 py-2 py-md-3 bg-dark text-white rounded-bottom-0 rounded-md-bottom-2'}>
		<div style={{ padding: '0 80px 0'}} className={'d-none d-md-block'}>
			<Row none>
				<Col xs={'auto'}><ButtonNew color={'light'} className={'text-hs'}>{'link:free-trial'.t()}</ButtonNew></Col>
				<Col style={{ padding: '0 0 0 70px'}} className={'text-lg-hs text-4  d-flex align-items-center'}>{'link:experience-text'.t()}</Col>
			</Row>
		</div>

		<div className={'d-block d-md-none'}>
			<Row none>
				<Col xs={12} style={{ padding: '0'}} className={'d-flex justify-content-center text-center'}>{'link:experience-text'.t()}</Col>
				<Col xs={12} className={'d-flex justify-content-center py-1'}><ButtonNew onClick={()=>{ historyPush('/register.html')}} color={'light'}  style={{ width: '180px', minHeight: '30px'}} className={'text-h4 py-0'}>{'link:free-trial'.t()}</ButtonNew></Col>
			</Row>
		</div>
	</div>)
}

const DataRow = ({ accessUrl, origin, full, id, alert, toggleQR, key, onEditUrl  }) => {
	// const { classes } = useStyles();
	return  (<>
		<div className={clsx('my-0 py-0 rounded-0 rounded-md-2 shadow bg-white')}>
			<div className={clsx('d-none d-md-block my-0 px-md-3 py-0 link-item widget')} >
				<Row none sx={sx} className={clsx('home-shorten-item d-flex align-items-center hidden')}>
					<Col xs={'auto'} className={'row-1'}>
						<Row small className={'row-md-none item-wrap d-flex justify-content-center align-items-center'}>
							<Col xs={'auto'} className={'item-wrap-1'}>
								<div className={'pl-2 pl-md-0'}><Logo className={'link-logo'} /></div>
							</Col>
							<Col className={'item-wrap-2'}>
								<div style={{ lineHeight: '39px', maxWidth: 'calc(100% - 15px)'}} className={'border-bottom border-width-2 text-secondary ellipsis ellipsis-word-break ellipsis-one'}>{origin}</div>
								<div style={{ lineHeight: '39px'}}  className={'d-flex align-items-center shared'}><ShareBtn className={'share-info rounded-0 m-0 py-1'} {...{ full, id, toggleQR }} /></div>
							</Col>
						</Row>
					</Col>
					<Col className={'row-2'}>
						<Row none style={{ maxWidth: 'calc(100%)'}} >
							<Col style={{ maxWidth: 'calc(100% - 200px)'}} className={clsx({ 'd-flex justify-content-center': !!accessUrl })}>
								<Row none className={clsx({ 'd-flex justify-content-center': !accessUrl })}>
									<Col xs={'auto'} style={{ maxWidth: 'calc(100% - 24px)'}}>
										<ClickToSelect className={'text-h1 font-weight-bold w-100 ellipsis ellipsis-word-break ellipsis-one text-nowrap text-primary mb-0 info-text'}>{full}</ClickToSelect>
									</Col>
									{(!!accessUrl) && <Col className={'justify-content-start align-items-center'}>
										<Button onClick={onEditUrl} color={'default'} className={'p-0'}><Pen width={'24px'}/></Button>
									</Col>}
								</Row>
							</Col>
							<Col xs={'auto'} style={{ width: '200px'}}>
								<CopyToClipboard text={full} onCopy={() =>alert({ message: 'copied'.t() })}>
									<ButtonNew block className={'text-hv text-white  border-0'} color={'orange'} style={{ maxWidth: '100%', width: '200px', minHeight: '50px'}}>{'link:copy.link'.t()}</ButtonNew>
								</CopyToClipboard>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>

			<div className={clsx('d-block d-md-none my-0 px-md-3 py-0 link-item widget')} >
				<Row sx={sx} none className={'home-shorten-item d-flex align-items-center hidden'}>
					<Col xs={'auto'} className={'row-1'}>
						<Row small className={'row-md-none item-wrap d-flex justify-content-center align-items-center'}>
							<Col xs={'auto'} className={'item-wrap-1'}>
								<div className={'pl-2 pl-md-0'}><Logo className={'link-logo'} /></div>
							</Col>
							<Col className={'item-wrap-2'}>
								<div style={{ lineHeight: '25px'}} className={'text-secondary ellipsis ellipsis-word-break ellipsis-one text-h5'}>{origin}</div>
								<div className={'w-100 mb-0'} style={{ lineHeight: '25px'}}>
									{(!accessUrl) && <strong className={'text-primary ellipsis ellipsis-one text-nowrap'}>
										<span>{full}</span>
									</strong>}

									{(!!accessUrl) && <>
										<Button style={{height: '25px'}} onClick={onEditUrl} color={'default'} className={'p-0'}>
											<strong className={'ellipsis ellipsis-one text-nowrap'}>
												<span className={'text-primary'}>{full}</span>
												<Pen width={'19px'} height={'19px'} />
											</strong>
										</Button>
									</>}
								</div>
							</Col>
							<Col xs={12}>
								<ShareBtnMobile className={'share-info border-0'}  {...{ share:{ title:full, url: full }, full, id, toggleQR, alert }} />
							</Col>
						</Row>
					</Col>
				</Row>
			</div>

			{(!key) && <DataFirst />}
		</div>
	</>);
}

const HomeLinks = compose(
	withLinkUpdate,
	withContentCallback(((res)=> res), 'loadPage', PAGE_QUERY),
	withContentCallback(((res)=> res), 'loadDomain', DOMAINS_QUERY),
	withContentCallback(((res)=> res), 'loadQrcode', QRCODE_QUERY, null, 'no-cache'),
	withProps(({ entities, linkCreate, linkUpdate })=>({
		onSave	: onSave(linkCreate, linkUpdate),
		refetch: func(entities.refetch)
	})),
	withProps(({ onSave, loadQrcode, refetch, links, alert, onActions })=>{
		let datasource = [];
		(links?.items || []).map(({ accessUrl, access, id, title, full, url: linkurl, domain, origin, qrurl, qr, ...rest }, key) => {

			let filename = `${linkurl}`.replace('/','');

			let linkProps = { qr, refetch, qrurl, origin, id, title, full, access, url: linkurl, domain, filename }

			datasource.push({
				id: key,
				title:{ qr, accessUrl, title, full, id, key, url: linkurl, domain, origin, linkProps }
			})
		});

		return { datasource,
			columns: [{
				dataIndex: 'title',
				className: 'align-middle position-relative py-1 py-md-2 pb-0 px-0',
				render: ({ domain, linkProps, ...rest}) => {
					async function toggleQR(){
						let path = `/node/${linkProps?.id}`;
						let { qr: values } = await loadQrcode({ path });

						func(onActions['qrcode'])({
							webType: 'cpanel',
							values, isQrTag: true,
							data_text: rest?.full,
							onSubmit: function (qr, { onClose }){
								onSave({ qr, id: linkProps?.id })
									.then(func(refetch))
									.then(func(onClose))
									.catch(errorCatch)
							}
						});
					}

					return DataRow({
						...rest, alert, toggleQR,
						onEditUrl: func(onActions['link.custom'])({ ...linkProps, title:  'link:form.custom.title'.t() })
					})
				}
			}]
		}
	})
)(({ columns, datasource }) => (<>
	{(!!datasource.length) && <Table
		itemProps={{xs: '12'}}
		className={'table-list home-links py-1 py-md-2'}
		columns={columns}
		datasource={datasource}
	/>}
</>));
export default HomeLinks;
